<template>
  <v-container>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-4">
        <v-icon color="primary" left>mdi-account-group</v-icon>
        Permissões
        <v-spacer></v-spacer>
        <v-btn @click="dialogAddPermissao = true" color="secondary">
          Adicionar
          <!-- :to="{ path: 'permissoes/add' }" -->
        </v-btn>
      </v-card-title>
      <ListaPermissoes />
    </v-card>
  
    <NovaPermissao
      v-if="dialogAddPermissao"
      :dialogAddPermissao.sync="dialogAddPermissao"
    />
  </v-container>
</template>

<script>
export default {
  name: "Permissoes",

  components: {
    ListaPermissoes: () => import("./components/ListaPermissoes.vue"),
    NovaPermissao: () => import("./NovaPermissao.vue"),
  },

  data() {
    return {
      dialogAddPermissao: false,
    };
  },

  mounted() {},

  created() {},
};
</script>

<style></style>
